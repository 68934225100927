<template>
  <div class="th-mb-24">
    <v-combobox
      v-model="chips"
      :search-input.sync="search"
      :hide-no-data="!search"
      :items="mappedItems"
      chips
      clearable
      :label="$translation.t(input.label)"
      :hint="$translation.t(input.hint)"
      persistent-hint
      multiple
      outlined
    >
      <template v-slot:no-data v-if="!input.noCreate">
        <v-list-item>
          <v-chip color="primary" label small>
            {{ search }}
          </v-chip>
          <span
            class="th-ml-12"
            v-html="
              $translation.t('Press <kbd>enter</kbd> to create a new one')
            "
          ></span>
        </v-list-item>
      </template>
      <template v-slot:selection="{ attrs, item, select, selected }">
        <v-chip
          color="primary"
          v-bind="attrs"
          :input-value="selected"
          close
          @click="select"
          @click:close="remove(item)"
        >
          <strong>{{ item }}</strong>
        </v-chip>
      </template>
    </v-combobox>
  </div>
</template>

<script>
export default {
  name: "ComboboxField",
  props: {
    input: {
      type: Object,
      required: true,
    },
    value: Array,
  },
  data: () => ({ chips: [], search: "" }),
  created() {
    this.chips = this.value.map((item) =>
      typeof item === "string" ? item : item.name
    );
  },
  computed: {
    mappedItems() {
      return this.input.values.map((item) =>
        typeof item === "string" ? item : item.name
      );
    },
  },
  methods: {
    remove(item) {
      this.chips = this.chips.filter((chip) => chip !== item);
    },
  },
  watch: {
    chips(newValue) {
      this.$emit(
        "input",
        newValue.map((item) => {
          const foundItem = this.input.values.find((i) =>
            typeof i === "string" ? i === item : i.name === item
          );

          const type = foundItem
            ? typeof foundItem === "string"
              ? "Other"
              : foundItem?.type
            : "Other";

          return {
            name: item,
            type: type,
          };
        })
      );
    },
    // value(newValue) {
    //     this.chips = newValue.map((item) =>
    //         typeof item === "string" ? item : item.name
    //     );
    // },
  },
};
</script>

<style scoped></style>
