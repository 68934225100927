var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"th-mb-24"},[_c('v-combobox',{attrs:{"search-input":_vm.search,"hide-no-data":!_vm.search,"items":_vm.mappedItems,"chips":"","clearable":"","label":_vm.$translation.t(_vm.input.label),"hint":_vm.$translation.t(_vm.input.hint),"persistent-hint":"","multiple":"","outlined":""},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event}},scopedSlots:_vm._u([(!_vm.input.noCreate)?{key:"no-data",fn:function(){return [_c('v-list-item',[_c('v-chip',{attrs:{"color":"primary","label":"","small":""}},[_vm._v(" "+_vm._s(_vm.search)+" ")]),_c('span',{staticClass:"th-ml-12",domProps:{"innerHTML":_vm._s(
            _vm.$translation.t('Press <kbd>enter</kbd> to create a new one')
          )}})],1)]},proxy:true}:null,{key:"selection",fn:function(ref){
          var attrs = ref.attrs;
          var item = ref.item;
          var select = ref.select;
          var selected = ref.selected;
return [_c('v-chip',_vm._b({attrs:{"color":"primary","input-value":selected,"close":""},on:{"click":select,"click:close":function($event){return _vm.remove(item)}}},'v-chip',attrs,false),[_c('strong',[_vm._v(_vm._s(item))])])]}}],null,true),model:{value:(_vm.chips),callback:function ($$v) {_vm.chips=$$v},expression:"chips"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }